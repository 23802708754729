$smallMultiCardWidth: 200px;
$smallMultiCardHeight: 200px;
$mediumMultiCardWidth: 350px;
$mediumMultiCardHeight: 180px;
$largeMultiCardWidth: 650px;
$largeMultiCardHeight: 150px;

@media (min-width: 960px) {
  .main-content-view {
    .choice-question-wrapper {
      .answers-block {
        margin-left: auto;
        margin-right: auto;

        &.small-multi-cards {
          width: 640px;
        }

        &.medium-multi-cards {
          width: 730px;
        }

        &.large-multi-cards {
          width: $largeMultiCardWidth;

          .card-answers-wrapper {
            .card-answers {
              width: $largeMultiCardWidth;
              height: $largeMultiCardHeight !important;
            }
          }
        }

        .card-answers-wrapper.uk-grid {

          .small-multi-cards-inner {
            .card-answers {
              width: $smallMultiCardWidth !important;
              height: $smallMultiCardHeight !important;
            }

            &:nth-child(3n-2):nth-last-child(2) {
              width: 50%;
              position: relative;
              height: $smallMultiCardHeight;
              right: 36px;

              .card-answers {
                left: 50%;
                position: absolute;
              }
            }

            &:nth-child(3n-1):last-child {
              position: relative;
              height: $smallMultiCardHeight;
              width: 50%;

              .card-answers {
                position: absolute;
              }
            }

            &:nth-child(3n-2):last-child {
              position: relative;
              width: 100%;

              .answer-cards {
                .card-answers {
                  margin-left: auto;
                  margin-right: auto;
                }
              }

            }
          }

          .medium-multi-cards-inner {
            .card-answers {
              position: absolute;
              width: $mediumMultiCardWidth !important;
              height: $mediumMultiCardHeight !important;
            }

            &:nth-child(2n -1) {
              position: relative;
              left: 5px;
              width: $mediumMultiCardWidth + 20px;
              height: $mediumMultiCardHeight;

              .card-answers {
                position: absolute;
                width: $mediumMultiCardWidth;
                height: $mediumMultiCardHeight;
              }
            }

            &:nth-child(2n) {
              position: relative;
              left: 5px;
              width: $mediumMultiCardWidth + 20px;
              height: $mediumMultiCardHeight;

              .card-answers {
                position: absolute;
                width: $mediumMultiCardWidth;
                height: $mediumMultiCardHeight;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 780px) {

  .main-content-view {
    .choice-question-wrapper {
      .answers-block {
        margin-left: auto;
        margin-right: auto;

        .card-answers-wrapper.uk-grid {
          .medium-multi-cards-inner {
            .answer-cards {
              .card-answers {
                height: $mediumMultiCardHeight;

              }
            }

            &:nth-child(2n-1):last-child {
              width: 100% !important;
              position: relative;

              .answer-cards {
                position: relative;
                padding-left: 10px;

                .card-answers {
                  position: relative;
                  margin-left: auto !important;
                  margin-right: auto !important;
                  width: $mediumMultiCardWidth;
                  height: $mediumMultiCardHeight;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) and (min-width: 960px) {

  .uploaded-files-wrapper {
    width: 32vw;
    .uploaded-file-item-wrapper {
      p.uploaded-file-item {
        width: 24vw;
        margin: 0 2.4vw;
      }

      .gi-law-delete-file-button {
        svg {
          width: 2vw;
        }
      }
    }
  }
  .c-btn.c-datepicker-btn,
  .file-upload-question{
    position: relative;
    .MuiInput-underline {
      padding: 1.6vw;
    }
    .MuiIconButton-root {
      padding: 1vw;
    }
    .fa-calendar-alt {
      font-size: 2.2vw !important;
    }

    input.MuiInput-input {
      margin-right: 3.2vw;
      &::placeholder {
        font-size: 1.44vw;
      }
    }
  }
}

@import '../base/colors';
// Buttons that we have changed

.modal-first-hr {
  margin: 20px -30px;
}

.modal-second-hr-top-green {
  margin: 0 -30px 30px -30px;
}

.modal-second-hr {
  margin: 40px -30px 30px -30px;
}

.uk-modal-title {
  &.primary {
    color: $primary;
  }
  &.warning {
    color: $red;
  }
  &.positive {
    color: $green;
  }
}

.card-answers-title {
  &.card-answers-title-error-dialog {
    color: $red;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.modal-content-no-margin-bottom {
  margin-bottom: -70px !important;
  @media only screen and (max-width : 960px) {
    margin-bottom: 0 !important;
  }
  img {
    max-width: 80% !important;
  }
}

.modal-no-padding-bottom {
  padding-bottom: 0 !important;
}

.gi-law-no-padding-bottom-modal {
  padding: 30px 30px 0 30px;
}

.gi-law-all-padding-modal {
  padding: 30px;
}

.gi-law-modal-title {
  margin-top: 0;
}

.git-law-modal-button-wrapper {
  margin-bottom: 0;
}

.gi-law-close-modal-button {
  img {
    height: 25px;
  }
}
.modal-green-bg-helpdesk {
  background-color: $light-green;
  margin: 20px -30px 0 -30px;
  padding: 30px;

  .modal-code-helpdesk-block {
    border: 1px solid $green;
    padding: 20px;
    font-size: 20px;
    width: 50%;
    border-radius: 4px;

    .modal-code-helpdesk-text {
      color: $green;
      text-transform: uppercase;
      span {
        color: #B7B7B7;
      }
    }
  }
}

.modal-p-margin-bottom {
  margin-bottom: 40px;
}

.modal-accept-checkbox {
  display: flex;
  flex-direction: row;
  margin: 40px 0 -20px 0;
  .modal-accept-checkbox-text {
    margin-left: 5px;
  }
}


h3 {
  &.primary-color {
    color: $primary !important;
  }

  &.success-color {
    color: $green !important;
  }

  &.warning-color {
    color: $warning !important;
  }

  &.danger-color {
    color: #C43C3C !important;
  }
}

.save-dialog-error {
  font-size: 15px;
  color: $red;
  &.hide-error-message {
    display: none;
  }
  &.show-error-message {
    margin-top: -17px !important;
    &.save-dialog-error-terms {
      margin-top: 20px !important;
    }
  }
}

.input-error-for-field {
  .uk-form-label {
    color: $red;
  }
  a {
    color: $red !important;
  }
  color: $red;
  .uk-input.save-dialog-input-field,
  .uk-checkbox.save-dialog-input-field,
  .uk-select.save-dialog-input-field {
    border-color: $red;
    color: $red ;
    -webkit-text-fill-color:$red;
    &:-webkit-autofill {
      color: $red ;
    }
    &::placeholder {
      color: $red;
    }
    &:-ms-input-placeholder {
      color: $red;
    }
    &::-ms-input-placeholder {
      color: $red;
    }
  }
}


.min-width-popup-dialog {
  min-width: 400px;
}

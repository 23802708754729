@import '../base/colors';

.uk-list-disc {
  padding-left: 0 !important;
}

.global-overview-list{
  padding: 20px 50px 20px 50px !important;
  .the-document {
    padding-left: 20%;
  }
}

.active-tab-list {
  background-color: $primary-off-white;
  color: $primary-medium;
  padding: 10px 0;
}

.no-active-tab-list {
  border: 1px solid $primary-off-white;
  background-color: $white;
  a {
    color: $no-active-tab !important;
  }
}

.tab-list-first {
  padding-right: 20px;
  border-top-left-radius: 4px;
  a {
    border-top-left-radius: 4px;
  }
}

.tab-list-second {
  padding: 0 0 0 -20px;
  margin-left: -20px;
  border-top-right-radius: 4px;
}
.helpdesk-list {
  &.header {
    color: $white;
  }
  .margin-center-left {
    margin: auto 0;
  }
  .helpdesk-list-number {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 15%;
    }
  }
  .helpdesk-list-sender {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 15%;
    }
  }
  .helpdesk-list-subject {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 15%;
    }
  }
  .helpdesk-list-date {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 16%;
    }
  }
  .helpdesk-list-extra-space {
    @media only screen and (max-width : 960px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width : 961px) {
      width: 23%;
    }
  }
}

.layout-list-btn-and-search {
  margin-top: -10px;
}

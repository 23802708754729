@import '../base/colors';
.view-debugger-outer {
  &.view-debugger-outer-hidden {
    visibility: hidden;
  }
  position: absolute;
  width: 100%;

  .view-debugger-wrapper {

    .positioner-outer {
      z-index: 50000;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      left: 0;

      .positioner-wrapper {
        @media (min-width: 960px) {
          margin-left: 260px;
        }
        height: 100vh;

        .positioner {
          border: thin red solid;
          border-top: none;
          border-bottom: none;
          height: 100vh;
          width: 900px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .font-viewer-outer {
      z-index: 50000;
      @media (min-width: 960px) {
        margin-left: 130px;
      }
      position: fixed;
      top: 45px;
      width: 100%;
      text-align: center;

      .font-viewer-wrapper {
        pointer-events: none;
        color: $primary-deep;
        text-align: center;
        max-width: 200px;
        padding: 10px;
        background-color: white;
        min-height: 85px;
        margin: auto;
        z-index: -1;
        .component-identifier-copy-to-clip {
          pointer-events: all;
          color: red;
        }
      }
      .path-viewer {
        background-color: $white;
        padding: 10px;
        margin: 10px;
        position: absolute;
        max-width: 400px;
        border: 3px solid $primary-dark;
        .no-margin {
          margin: 0 !important;
        }
      }
    }

    .clear-local-storage-outer {
      z-index: 51000;
      @media (min-width: 960px) {
        margin-left: 130px;
      }
      pointer-events: all;
      position: fixed;
      width: 100%;

      .clear-local-storage-wrapper {
        background-color: white;
        margin: auto;
        max-width: 200px;
        text-align: center;
        pointer-events: all;
        padding: 20px 10px 10px 10px;

        button {

        }

      }
    }

  }
}

.toggle-button-outer {
  z-index: 159000;
  @media (min-width: 960px) {
    margin-left: 130px;
  }
  position: fixed;
  top: 0;
  width: 100%;

  .toggle-button-wrapper {
    text-align: right;

    max-width: 200px;
    padding: 0;
    margin: auto;

    button {
      margin-right: -6px;
      border: thin $simple-grey solid;
      padding: 6px 2px;
      min-height: 14px;
      border-radius: 50%;
    }
  }

  &.toggle-button-outer-hidden {
    .toggle-button-wrapper {
      button {
        border-color: transparent !important;

        img {
          display: none;
        }
      }

      &:hover {
        button {
          border-color: $simple-grey !important;
          background-color: rgba(255, 255, 255, .2);

          img {
            display: block;
          }
        }
      }
    }
  }
}

// show central position END

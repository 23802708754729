@import '../base/colors';

textarea {
  resize: none;
  font-family: 'Fira Sans Condensed', sans-serif !important;
  outline-color: transparent;
  outline: none !important;
  ::selection {
    border-color: $primary-medium !important;
  }
}

.uk-input {
  &.form-with-only-border-bottom {
    border-bottom: 1px solid #e5e5e5 !important;
    border: none;
  }
}

.uk-search-input {
  height: 44px !important;
}

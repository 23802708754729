
/* VARIABLES (defined in style.scss) */

$primary:  var(--primary-one);
$secondary-bg-color: var(--secondary-bg-color);
$accent-footer: var(--accent-footer);
$lighter-grey: var(--lighter-grey);
$primary-disabled:var(--primary-disabled);
$primary-blue: var(--primary-blue);
$primary-dark: var(--primary-dark);
$primary-medium: var(--primary-medium);

/* CONSTANTS */

$primary-text-color: #272727;
$white: #ffffff;
$primary-white-link: #DDC8E1;
$green: #95a126;
$light-green: #EDF2DF;
$warning: #D0A45D;
$warm-gold: #C89933;
$red: #C43C3C;
$primary-off-white: #F0EDF2;
$no-active-tab: #a286a1;
$circle-done: #B39FB2;
$medium-grey: #5a5858;
$simple-grey: #CCCCCC;
$ash-grey: #ffffff3d;
$primary-deep: #582F60;

/* filters for answer card icon */
$icon-filter-no-frame: invert(73.39985320594926%) sepia(38.470655734971146%) saturate(696.5904031782366%) hue-rotate(207.09335023678585deg) brightness(95.87309589691061%) contrast(87.97195763970039%);
$icon-filter-frame: brightness(0) saturate(100%) invert(35%) sepia(85%) saturate(5%) hue-rotate(1deg) brightness(93%) contrast(88%);

.test-the-colors {
  background-color: black;
  .primary {
    background-color: $primary;
    color: white;
  }
  .primary-dark {
    background-color: $primary-dark;
    color: white;
  }
  .primary-medium {
    background-color: $primary-medium;
    color: white;
  }
  .primary-disabled {
    background-color: $primary-disabled;
    color: white;
  }
  .secondary-bg-color {
    background-color: $secondary-bg-color;
    color: white;
  }
  .primary-text-color {
    background-color: $primary-text-color;
    color: white;
  }
  .whiteLink {
    background-color: $primary-white-link;
    color: $primary-text-color;
  }
  .primary-off-white {
    background-color: $primary-off-white;
    color: $primary-text-color;
  }
  .medium-grey {
    background-color: $medium-grey;
  }
  .primary-deep {
    background-color: $primary-deep;

  }
  .primary-blue {
    background-color: $primary-blue;
    color: white;
  }
  div {
    border: thin solid $simple-grey;
    color: $primary;
    font-size: 50px;
    width: 30vh;
    height: 30vh;
    float: left;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    word-wrap: anywhere;
    text-align: center;
  }
}

.primary-color {
  color: $primary !important;
}

a {
  color: $primary !important;
  &.navbar {
    color: $white !important;
  }
}
// Gilaw End

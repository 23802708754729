@import '../base/colors';

.gi-law-date-picker {
  button {
    color: $white;
  }
}

div.month-year-wrapper {
  align-self: center;
  padding-top: 16px;

  button.date-picker-month {
    align-self: flex-start;
    padding: 5px;

    h4 {
      font-weight: 400;
      color: $white;
      font-size: 24px;
      line-height: 36px;
    }
  }

  button.date-picker-year {
    align-self: flex-end;
    padding: 5px;

    h4 {
      color: $white;
      font-weight: 400;
      line-height: 36px;
      font-size: 24px;
    }
  }
}
.date-picker-caret {
  color: $white;
}

.date-picker-weekday-wrapper {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;

  .date-picker-weekday {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
    color: $white;
  }
}

button.date-picker-day {
  width: 100%;

  h1 {
    font-size: 64px;
    line-height: 96px;
    font-weight: 400;
    color: $white;
    width: 100%;
    text-align: center;
  }
}

@import 'colors';

html {
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
}

h1, .uk-h1, h2, .uk-h2, h3, .uk-h3, h4, .uk-h4, h5, .uk-h5, h6, .uk-h6, .uk-heading-small, .uk-heading-medium, .uk-heading-large, .uk-heading-xlarge, .uk-heading-2xlarge, dt, dd {
  font-family: 'Fira Sans Condensed', Montserrat, sans-serif !important;
}

.montserrat-font {
  font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;

  &.montserrat-font-white {
    color: $white !important;
  }
}


p {
  font-size: 16px !important;
}

.question-answer-wrapper {
  @media only screen and (max-width: 481px) {
    p {
      font-size: 14px !important;
    }
  }
}

ul li,
ol li {
  @media only screen and (min-width: 481px) {
    font-size: 16px !important;
  }
}

h3 {
  @media only screen and (min-width: 481px) {
    font-size: 28px !important;
  }
}

span {
  @media only screen and (min-width: 481px) {
    font-size: 16px !important;
    &.uk-badge {
      font-size: 15px !important;
    }
  }
}

.dossier-subtitle {
  @media only screen and (min-width: 481px) {
    font-size: 23px !important;
  }
}

.dossier-progress-details {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
  }
}

form {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
  }
}

.modal-accept-checkbox {
  @media only screen and (min-width: 481px) {
    font-size: 16px !important;
  }
}

.modal-accept-checkbox-text {
  @media only screen and (min-width: 481px) {
    font-size: 16px !important;
  }
}

label {
  @media only screen and (min-width: 481px) {
    font-size: 15px !important;
  }
}

.dossier-check {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
  }
}

a {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
    text-decoration: underline !important;
  }
}

.modal-accept-checkbox {
  a {
    @media only screen and (min-width: 481px) {
      font-size: 16px !important;
    }
  }
}

html {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
  }
}

button {
  @media only screen and (min-width: 481px) {
    /* diverging-needs-attention I think that this is the speak-text-button icon */
    &.circle-btn {
      font-size: 18px !important;
    }
    font-size: 0.925rem !important;
  }
}

textarea {
  @media only screen and (min-width: 481px) {
    font-size: 18px !important;
  }
}

/*
diverging-needs-attention
@see StartQuestion
 */
.fira-sans-bold {
  font-weight: 600;
}

.react-markdown-wrapper {
  height: 100% !important;

  em {
    color: unset;
  }

  code {
    text-decoration: underline;
    color: unset;
    background-color: unset;
  }
}

.react-markdown-text-wrapper {
  .react-markdown-wrapper{
    color: $white;

    em,
    code,
    strong {
      color: $white;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: $primary-white-link !important;
    }
  }

  }

.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
}

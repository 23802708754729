
.markdown-navigation-tooltip {
  font-family: 'Montserrat', 'Fira Sans Condensed', sans-serif !important;
  p {
    margin: 0;
    font-weight: 400 !important;
    font-size: 11.25px !important;
  }
  strong {
    font-weight: 900 !important;
    //color: red;
  }
  em {
    color: unset;
  }
}

.MuiTooltip-tooltip {
  font-family: 'Montserrat', 'Fira Sans Condensed', sans-serif !important;
  font-size: 11.25px !important;
}

@import '../base/colors';

nav {
  &.background-gradient-nav {
    background: $secondary-bg-color;
    background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
    background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
    background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  }
}

.name-person-navbar {
  width: 160px;
  padding-top: 5px;

  span {
    display: inline-block;
  }
  .name-person {
    width: 135px;
    margin-right: 5px !important;
  }
  .navbar-name-person {
    position: absolute;
  }
}

.navbar-desktop {
  img {
    height: 30px;
    margin: 20px
  }
  .navbar-space-right {
    padding-left: 45px;
  }
  .navbar-name-person {
    i {
      margin-bottom: 8px;
      padding-left: 10px;
    }
  }
}

.uk-navbar-nav > li > a {
  text-transform: none !important;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: $white !important;
}

.navbar-footer-beheerder {
  margin: 25px 280px !important;
}
@import '../base/colors';
// Buttons that we have changed

.before-next-button-wrapper {

  .previous-and-next-button {
    user-select: none;
    @media only screen and (min-width: 960px) {
      padding: 13px 25px !important;
      font-size: 1.2rem !important;
    }
  }

  .previous-and-next-button {
    svg {
      user-select: none !important;
    }
  }

  position: fixed;
  bottom: 0;
}

.uk-button {
  font-size: 1rem !important;
  border-radius: 4px !important;
  padding: 4px 20px !important;
  line-height: 34px !important;
  text-transform: initial !important;
  font-weight: 400 !important;
  &:disabled {
    svg {
      &.before {
        @media only screen and (max-width: 480px) {
          margin-right: 5px;
          margin-left: -8px;
        }
        @media only screen and (min-width: 481px) {
          margin-right: 14px;
          margin-left: -6px;
        }
      }

      &.after {
        @media only screen and (max-width: 480px) {
          margin-left: 4px;
          margin-right: -9px;
        }
        @media only screen and (min-width: 481px) {
          margin-left: 11px;
          margin-right: -5px;
        }
      }
    }
  }
  svg {
    &.before {
      @media only screen and (max-width : 480px) {
        margin-right: 7px;
      }
      @media only screen and (min-width : 481px) {
        margin-right: 20px;
      }
    }
    &.after {
      @media only screen and (max-width : 480px) {
        margin-left: 5px;
      }
      @media only screen and (min-width : 481px) {
        margin-left: 17px;
      }
    }
  }
}
.close-modal-button.uk-button {
  margin-right: 5px;
}

.uk-button-default-gl {
  color: $white !important;
  background-color: transparent !important;
  border: 1px solid $white !important;
}

.uk-button-default-gl:hover {
  color: $white !important;
  background: rgba(255, 255, 255, 0.3) !important;
}

.uk-button-primary {
  background-color: $white !important;
  color: $primary  !important;
}

.uk-button-primary:hover {
  color: $white;
  background: rgba(255, 255, 255, 0.3);
}

.uk-modal-footer  .uk-button-primary,
.uk-button-secondary,
.uk-button-secondary.MuiButtonBase-root{
  background-color: $primary !important;
  color: $white !important;
  .MuiButton-label {
    color: $white !important;
  }
  border: transparent 1px solid;
  span.MuiButton-label {
    width: unset;
  }
  &.MuiButtonBase-root{
    min-width: unset;
  }
}
.uk-button-secondary.MuiButtonBase-root:disabled {
  background-color: $primary-disabled !important;
  pointer-events: auto;
  &:hover {
    background-color: $primary !important;
  }
}

.uk-button-stop {
  background-color: $red !important;
  color: $white !important;
}

.uk-button-stop:hover {
  background-color: #A22b2b !important;
  color: $white !important;
}

.uk-button-accept {
  background-color: $green !important;
  color: $white !important;
}

.uk-button-accept:hover {
  background-color: #5b6418 !important;
  color: $white !important;
}

.uk-button-secondary {
  background-color: $primary !important;
  color: $white !important;
}

.hover-dark-purple:hover {
  background-color: $primary-dark !important;
}

.uk-button-secondary:hover  {
  background-color: $primary-dark !important;
}

.uk-modal-footer  .uk-button-default,
.button-default-purple-border {
  color: $primary !important;
  background-color: $white !important;
  &.missing-answer {
    border-color: $white !important;
    background-color: transparent !important;
    color: $white !important;
    padding: 0 !important;
  }
  border: 1px solid $primary !important;
  .MuiButton-label {
    color: $primary !important;
  }
}

.uk-modal-footer  .uk-button-default:hover,
.hover-transparent.uk-button:hover {

  &:not([disabled]) {
    background-color: $lighter-grey !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
  border-radius: 4px !important;
  &.circle-btn {
    border-radius: 50% !important;
  }
}

.hover-dark-purple:hover {
  background: $primary-dark !important;
  border-radius: 4px !important;
}

.hover-white:hover {
  background: $white !important;
  border-radius: 4px !important;
}

.uk-subnav > .uk-active > a {
  background-color: $primary-medium !important;
  color: $white !important;
}

.sticky-fixed-bottom {
  a, .uk-link {
    color: #cec0d6 !important;
    &.active {
      color: $primary !important;
    }
  }
}

a {
  &.active-pagination {
    color: $white !important;
  }
}

a {
  color: $primary !important;
}

.button-before-search {
  float: right;
  margin-right: 10px !important;
}

.missing-answer-button {
  @media (min-width: 960px) {
    margin: 0 0 0 40px;
    pointer-events: all;
  }
  @media (max-width: 959px) {

    margin: 0 0 0 25px;
  }
}

.home-page-wrapper.hide-sidebar-when-embedded {

  .before-next-button-wrapper {
    .before-button {
      @media (min-width: 960px) {
        margin: 0 0 40px 40px;
        pointer-events: all;
      }
    }
  }
}
.before-next-button-wrapper {
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-self: baseline;
  @media (max-width: 959px) {
    background-color: $white;
    height: 96px;
    margin-top: 20px;
    box-shadow: 0 5px 15px #00000069 !important;
  }

  .next-question-missing-answer-wrapper {

  }
  .before-button {
    @media (min-width: 960px) {
      margin: 0 0 40px 300px;
      pointer-events: all;
    }
    @media (max-width: 959px) {
      margin: 0 0 25px 25px;
      bottom: 0;
      left: 0;
      position: absolute;

      /*.gi-law-button-primary {
        background-color: $primary !important;
        color: $white !important;
      }
      .uk-button-primary:hover {

      }
      //*/
    }
  }
  .next-button,
  .start-button,
  .before-button {
    align-self: end;
    pointer-events: all;
    button:disabled {
      background-color: rgba(255,255,255,0.3) !important;
      &.uk-button-primary{
        border-color: transparent !important;
      }
      @media (max-width: 959px) {

      }
    }

    @media (max-width: 959px) {
      margin-bottom: 30px;
      .uk-button-primary {
        background-color: $primary !important;
        color: $white !important;
        &:disabled {
          background-color: $primary-disabled !important;
        }
      }
      .uk-button-primary:hover {
        &:not([disabled]) {
          background-color: $primary-dark !important;
        }
      }
    }

  }
  @media (max-width: 959px) {
    .uk-button-primary {
      background-color: $primary !important;
      color: $white !important;
      &:disabled {
        background-color: $primary-disabled !important;
      }
    }
    .uk-button-primary:hover {
      background-color: $primary-dark !important;
    }
  }

  .next-button,
  .start-button{
    pointer-events: all;
    @media (min-width: 960px) {
      margin: 0 40px 40px 0;
    }
    @media (max-width: 959px) {
      margin: 0 25px 30px 0;
      bottom: 0;
      right: 0;
      position: absolute;


    }
  }
}
.start-button{
  pointer-events: all;

  button:disabled {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-color: transparent;
  }
}
.green-banner {
  button:disabled {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-color: transparent;
  }
}
.save-client-button.uk-button-secondary.MuiButtonBase-root:disabled,
.request-help-button.button-default-purple-border:disabled {
  background-color: $primary-disabled !important;
  border-color: transparent !important;
  .MuiButton-label{
    color: $white !important;

  }
}
.start-button.inline {
  display: inline-block;
}

.show-icon-when-disabled:enabled{
  .show-when-disabled {
    display: none;
  }
}

.info-icon-wrapper {
  position: absolute;
  top: 3px;
  right: 3px;
  line-height: 18px;
  width: auto !important;
  padding: 2px;
  svg {
    path {
      fill: $white !important;
    }
  }
}

.button-is-submitting {
  cursor: progress !important;
}

.inline-start-button .uk-button,
.inline-start-button .uk-button:disabled,
.block-start-button .uk-button,
.block-start-button .uk-button:disabled {
  svg {
    &.before {
      @media only screen and (max-width : 480px) {
        margin-right: 7px;
      }
      @media only screen and (min-width : 481px) {
        margin-right: 20px;
      }
    }
    &.after {
      @media only screen and (max-width : 480px) {
        margin-left: 5px;
      }
      @media only screen and (min-width : 481px) {
        margin-left: 17px;
        margin-right: 0;
      }
    }
  }
}
.span-holder-hover-transparent:hover button {
  &:not([disabled]) {
    background-color: $lighter-grey !important;

}
}
.uk-button.MuiButtonBase-root.Mui-disabled.valid-submit-button {
  cursor: progress !important;
}
.uk-button.MuiButtonBase-root.Mui-disabled.invalid-submit-button {
  cursor: not-allowed !important;
  &:disabled {
    pointer-events: all;

  }
}
.missing-answer-button {
  pointer-events: all;
  margin-bottom: 10px;
  cursor: zoom-in;
  z-index: 50000;

  .spacer-for-button {
    display: inline-block;
    font-size: 21.6px !important;
    padding: 12px 25px ;
    color: transparent !important;
    .fake-icon {
      width: 21.6px;
      margin-left: 19px;
      border: thin transparent solid;
    }
  }

 .missing-answer-button-inner-wrapper {
   position: absolute;
   width: 155px;
   display: flex;
   flex-flow: row nowrap;
   align-content: center;
   height: 62px;
   align-items: center;
   justify-content: center;
   justify-items: center;
   text-align: left;
   .missing-answer-button-img {
     display: flex;
     flex-direction: column;
     width: 50px;
     margin: 0;
     padding: 4px;

     img{
       width: auto;
       max-width: 100% !important;
       padding: 0;
       margin: 0;
     }
   }
   .missing-answer-button-text {
     display: block;
     padding: 2px;
     font-size: 14px !important;
     line-height: 14px;
   }
 }
}

@import '../base/colors';

.green-banner {
  color: $white;
  border: 1px solid $white;
  border-radius: 8px;
  margin-left: 285px;
  padding: 8px 15px;
  width: 600px;
  &.screen-share-by-admin {
    background-color: $warm-gold !important;
  }
  &.screen-share-by-client {
    background-color: $green;
  }
  z-index: 1350;
  .green-banner-text {
    padding-bottom: 2px;
  }
  .green-banner-text {
    padding-bottom: 2px;
  }
  .green-banner-button {
    margin-bottom: 0;
  }
  @media (max-width: 1204px) {
    width: 550px;
  }
  @media (min-width: 1205px) {
    width: 600px;
  }

  /*
  diverging-needs-attention
  layout breaks at medium size screens
  */
  @media (max-width: 1100px) {
    width: 500px;
  }
  @media (max-width: 1000px) {
    width: 450px;
  }

}

@import '../base/colors';
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@400;500;600;700&family=Montserrat:wght@400;500;700&display=swap");

.MuiSnackbar-anchorOriginTopCenter {

  @media (min-width: 600px) {
    top: 25% !important;
  }
  @media (min-width: 960px) {
    margin-left: 130px;
  }

  .MuiAlert-filledWarning {
    color: $primary;
    background-color: $white;
    border: thin $primary solid;
  }
}

.MuiPaper-root {
  color: #666;
}

.MuiButton-root {
  min-width: 0 !important;
  font-family: "Fira Sans Condensed", Montserrat, sans-serif !important;
  letter-spacing: normal !important;
}

.MuiButton-root:hover {
  background-color: transparent;
}

.MuiButton-text {
  padding: 0;
}

.MuiButtonBase-root {
  &.gi-law-close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;

    .MuiButton-label {
      width: unset;
    }
  }
}

.MuiTouchRipple-root {
  display: none;
}
.date-picker-wrapper:hover  {
  .c-btn.c-datepicker-btn {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.c-btn.c-datepicker-btn {
  .MuiButton-label {
    color: $medium-grey;
    text-transform: none;
    font-size: 16px;
  }

  .keyboard-date-picker {
    .MuiIconButton-label {
      svg {
        transform: scale(1.3);
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInput-underline {
    padding: 20px;

    &:before,
    &:after {
      border: thin transparent solid !important
    }
  }

  .MuiInput-input {
    text-align: center;
    margin-right: 37px;
  }

  .MuiDialog-root {
    font-family: "Montserrat", sans-serif;
  }

  .MuiInputBase-input {
    font-family: "Montserrat", sans-serif;
  }
}

.MuiPickersModal-dialogRoot {

  .MuiPickersToolbar-toolbar {
    background: linear-gradient(to right, $secondary-bg-color 0%, $primary-medium 100%);
    height: 200px;
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: #0000008a;
    font-weight: 700;
    font-family: "Fira Sans Condensed", sans-serif;
  }

  .MuiPickersDay-day {
    color: #000000cc;

    &:hover {
      background-color: $primary-medium;
      color: $white;
    }

    font-family: "Fira Sans Condensed", sans-serif;
  }

  .MuiPickersDay-daySelected {
    background-color: #0000000d;
    color: #000000cc;

    &:hover {
      background-color: $primary-medium;
      color: $white;
    }

  }

  .MuiTypography-body1 {
    font-family: "Fira Sans Condensed", sans-serif;
    font-weight: 800;
    color: #666;
    font-size: 14px;
  }

  .MuiTypography-body2 {
    font-family: "Fira Sans Condensed", sans-serif;
  }

}
.hide-snackbar {
  display: none;
}

@import '../base/colors';

.card-the-help-function {
  margin-top: -22px !important;
  padding: 12px 12px 12px 25px !important;
  border: 1px solid #653c6e !important;
  border-bottom-left-radius: 4px !important;
  background-color: $primary-off-white !important;
  h3 {
    color: #653c6e !important;
    margin-bottom: 10px !important;
    font-size: 26px !important;
    font-weight: 400 !important;
  }
}

// If you want to override variables do it here
:root {
  --primary-one: #653c6e;
  --secondary-bg-color: #4067A1;
  --accent-footer: #918cb7;
  --lighter-grey: #CCC0CC;
  --primary-disabled: rgba(101,60,110, 0.3);
  --primary-blue: #4e487d;
  --primary-dark: #4b2653;
  --primary-medium: #5C2C5A;
}
// import front end styles
@import 'base/colors';
@import 'base/font';
@import 'base/icon';
@import 'base/margin';
@import 'base/video';
@import 'components/badge';
@import 'components/banner';
@import 'components/buttons';
@import 'components/checkbox';
@import 'components/datepicker';
@import 'components/disc';
@import 'components/help-function';
@import 'components/inputfield';
@import 'components/list';
@import 'components/material-ui-themes';
@import 'components/modal';
@import 'components/navbar';
@import 'components/pagination';
@import 'components/progress-bar';
@import 'components/search';
@import 'components/datepicker';
@import 'components/tabs';
@import 'components/text';
@import 'components/toasters';
@import 'components/tooltip';
@import 'pages/question-pages';
@import 'pages/position-checker';
@import 'pages/dossier-details';
@import 'pages/react-components';
@import 'pages/fix-layout';
@import 'pages/fix-layout-mobile';

// 2. Import default variables and available mixins.
@import "../../node_modules/uikit/src/scss/variables-theme.scss";
@import "../../node_modules/uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() {
  color: $button-primary-background;
}

// 4. Import UIkit.
@import "../../node_modules/uikit/src/scss/uikit-theme.scss";

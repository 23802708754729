@import '../base/colors';

.uk-progress.progress-gilaw::-webkit-progress-value {
  background-color: $primary;
}
.uk-progress.progress-gilaw::-moz-progress-bar {
  background-color: $primary;
}
.uk-progress.progress-gilaw::-ms-fill {
  background-color: $primary;
}

$smallMultiCardWidth: 200px;
$smallMultiCardHeight: 200px;
$twoAnswerCardsWidth: 350px;
$twoAnswerCardsHeight: 180px;
$oneAnswerCardWidth: 650px;
$oneAnswerCardHeight: 150px;

* ::-webkit-scrollbar {
      &:not(.is-in-frame) {
      width: 0px ;
      height: 0px !important;
    }
  }
* {
  &:not(.is-in-frame) {
    // firefox
    scrollbar-width: none !important;
  }
}

.main-content-view {
  .choice-question-wrapper {
    .answers-block {

      .card-answers-wrapper.uk-grid {
        .answer-cards {
          .large-multi-cards-card,
          .large-multi-cards-card {
            max-width: 90vw;
          }
        }
      }
    }
  }
}

@media (max-width: 959px) {
  .main-content-view {


    .card-answers-title {
      h2 {

        width: 95vw;
      }
    }

    .choice-question-wrapper {
      .answers-block {
        width: 95vw;
        max-width: 95vw;
        margin-left: auto;
        margin-right: auto;
        .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
          margin-top: 10px !important;
        }

        &.small-multi-cards {
          width: 410px;
        }
        &.medium-multi-cards {
          margin-left: auto;
          margin-right: auto;
          width: 720px;
          .card-answers-wrapper.uk-grid.uk-child-width-1-2 {

          }
        }

        padding: 0;


        &.large-multi-cards {
          width: $oneAnswerCardWidth;
          max-width: 90vw;

          .card-answers-wrapper {
            .card-answers {
              width: $oneAnswerCardWidth;
              height: $oneAnswerCardHeight !important;
            }
          }
        }


        .card-answers-wrapper.uk-grid.uk-child-width-1-2 {

          margin-left: -10px;

          .small-multi-cards-inner {
            .card-answers {
              width: $smallMultiCardWidth;
              height: $smallMultiCardHeight;
            }

            &:nth-child(2n-1) {
              .card-answers {
                margin-right: -10px;
              }

              &:last-child {
                width: 100%;

                .card-answers {
                  margin-left: auto;
                  margin-right: auto;
                }
              }

            }

            &:nth-child(2n) {

              .card-answers {

              }
            }
          }

          .medium-multi-cards-inner {

            padding-left: 10px !important;
            .answer-cards {
              padding: 0;
            }

            &:nth-child(2n-1) {
              position: relative;
              left: 5px;
              width: $twoAnswerCardsWidth + 10px;
              height: $twoAnswerCardsHeight;
              .card-answers {
                position: absolute;
                width: $twoAnswerCardsWidth;
                height: $twoAnswerCardsHeight;
              }
              &:last-child {
                left: -4px;
                .card-answers {

                }
              }
            }

            &:nth-child(2n) {
              position: relative;
              left: 5px;
              width: $twoAnswerCardsWidth + 10px;
              height: $twoAnswerCardsHeight;

              .card-answers {
                position: absolute;
                width: $twoAnswerCardsWidth;
                height: $twoAnswerCardsHeight;
              }
            }
          }


        }
      }
    }
    .c-btn.c-datepicker-btn {
      input.MuiInput-input {
        margin-right: 48px;
      }
    }
  }
}

@media (max-width: 430px) {

  .main-content-view {
    .choice-question-wrapper {
      .answers-block {
        .card-answers-wrapper.uk-grid.uk-child-width-1-2 {
          .small-multi-cards-inner {
            width: 100%;
            .card-answers {
              width: 90vw;
              margin-left: auto !important;
              margin-right: auto !important;
            }
          }
        }
      }
    }
  }
}


$deleteButtonMinimum: 20px;
@media (max-width: 749px) {

  .main-content-view {
    .choice-question-wrapper {
      .answers-block {

        .card-answers-wrapper.uk-grid {
          &.uk-child-width-1-2 {
            .medium-multi-cards-inner {
              padding: 0 !important;
              margin-left: auto !important;
              margin-right: auto !important;
              width: $twoAnswerCardsWidth;
              min-height: $twoAnswerCardsHeight;
              max-width: 90vw !important;
              .card-answers {
                max-width: 90vw !important;
              }
            }

          }
        }

      }
    }

    .uploaded-files-wrapper {
      width: 53vw;
      .uploaded-file-item-wrapper {
        p.uploaded-file-item {
          width: 40vw;
          margin: 0 4vw;
        }

        .gi-law-delete-file-button.MuiButton-root {
          width: 3vw;
          min-width: $deleteButtonMinimum !important;
          min-height: $deleteButtonMinimum !important;
          height: 3vw;
          padding: 0.5vw;
          border-radius: 2px;
          margin-top:3px;
          svg {
            width: 2.5vw;
            height: 2.5vw;
          }
        }
      }
    }
  }
}


@media (max-width: 300px) {
  .before-next-button-wrapper {
    height: 30vw;

    .next-button,
    .before-button {
      margin: 8.3vw;

      button.uk-button {
        font-size: 5vw !important;
        padding: 1.3vw 6vw !important;
        line-height: 11.3vw !important;
      }
    }
  }
  .file-upload-question-inner {
    padding: 10vw 10vw 0 10vw !important;
    button.uk-button {
      font-size: 5.3vw !important;
      line-height: 11vw !important;
      padding: 7vw 3px !important;
    }
    p {
      font-size: 4.8vw !important;
      margin-top: 7vw ;
      margin-bottom: 7vw;
    }
  }
  .uk-navbar-item.main-navigation {
    padding-left: 5vw;
    padding-right: 5vw;
    min-height: 27vw;
    button.uk-button.save-button,
    button.uk-button.help-button-top {
      font-size: 5vw !important;
      padding: 1.3vw 6vw !important;
      line-height: 11.3vw !important;
      margin-top: 7vw;
    }

    button.uk-button.save-button {
      margin-left: 3.25vw !important;
      margin-right: 3.25vw !important;
    }

    button.uk-icon-button.speak-text-button {
      width: 15vw !important;
      height: 15vw !important;
      right: 8vw;
      margin-top: 7vw;
    }
  }
}

@media (max-width: 480px) {
    .c-btn.c-datepicker-btn,
    .file-upload-question{
      position: relative;
      .MuiInput-underline {
        padding: 4vw;
      }
      .MuiIconButton-root {
        padding: 2.5vw;
      }
      .fa-calendar-alt {
        font-size: 5.5vw !important;
      }

      input.MuiInput-input {
        margin-right: 9vw !important;
        position: relative;
        width: 100%;
        &::placeholder {
          font-size: 3.75vw;
        }
      }
    }
    .uk-checkbox {
      max-width: 4vw;
      max-height: 4vw;
      border-radius: 1vw !important;
    }
    label {
        font-size: 3.75vw !important;
    }
}

@import '../base/colors';

// hr
.hr-without-margin {
  margin: 0;
}

.hr-without-space {
  @media only screen and (max-width : 480px) {
    margin: 20px -25px !important;
  }
  @media only screen and (min-width : 481px) {
    margin: 20px -50px !important;
  }
}

// the dossier container
.dossier-container {
  @media only screen and (max-width : 960px) {
    margin: 30px 20px 100px 20px;
  }

  @media only screen and (min-width : 961px) {
    margin: 152px 100px 60px 100px;
  }

  &.dossier-container-organisation {
    @media only screen and (max-width : 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width : 961px) {
      margin: 80px 100px 95px 100px;
    }
  }

  &.list-container-support {
    @media only screen and (max-width : 960px) {
      margin: 30px 20px 100px 20px;
    }

    @media only screen and (min-width : 961px) {
      margin: 80px 50px 95px 50px;
    }
  }

  // Card body
  .uk-card-body {
    @media only screen and (max-width : 480px) {
      padding: 30px 20px;
    }
  }

  // Card radius
  .uk-card {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  // The tabs
  .dossier-tabs {
    margin-bottom: -2px;
    ul {
      margin-bottom: 0;

      &.uk-tab > * {
        &.right-tab {
          padding-left: 0 !important;
        }
      }
      .active-tab {
        padding: 10px;
        border-top-left-radius: 5px;
        background-color: $primary-off-white;
        color: $primary-medium;
      }
      .left-tab {
        border-top-left-radius: 5px;
      }
      .right-tab {
        border-top-right-radius: 5px;
      }
      .no-active-tab {
        margin-left: -20px;
        padding: 10px;
        border: 1px solid $primary-off-white;
        border-top-right-radius: 5px;
        background-color: $white;
        color: $primary-medium;
      }
    }
  }

  // dossier header
  .dossier-header {
    background-color: $primary-deep;
    border-top-right-radius: 3px;
    // remove top left radius - if the tabs are available
    border-top-left-radius: 3px;
    padding: 20px 50px;
    @media only screen and (max-width : 480px) {
      padding: 15px 25px;
    }
    @media only screen and (max-width : 960px) {
      border-top-left-radius: 3px;
    }
    h3 {
      color: $white;
    }
  }

  // Subheader - alert message
  .message-alert {
    padding: 15px 50px;
    background-color: #F4DADA;
    @media only screen and (max-width : 480px) {
      padding: 15px 25px;
    }
    svg {
      color: $red !important;
      margin-right: 10px;
    }
  }

  // dossier subtitle
  .dossier-subtitle {
    padding: 25px 50px;
    background-color: $primary-off-white;
    color: $primary-deep;
    font-weight: 600;
    font-size: 20px;
    @media only screen and (max-width : 480px) {
      padding: 20px 25px;
    }
  }

  // dossier paragraph
  .dossier-p {
    padding: 50px;
    .design-status {
      color: $warning;
      margin-left: 20px
    }
    input {
      font-size: 14px;
    }
    .dossier-progress-gilaw {
      margin-bottom: 30px;
    }
    .label-inputfield {
      @media only screen and (min-width : 481px) {
        width: 120px !important;
      }
    }
    .form-inputfield {
      input {
        padding: 0 !important;
      }
      @media only screen and (min-width : 960px) {
        margin-left: 144px !important;
        input {
          width: 200px !important;
        }
      }
    }
    @media only screen and (max-width : 480px) {
      padding: 25px;
    }
    .space-list-mobile {
      @media only screen and (max-width : 960px) {
        padding-top: 20px;
      }
    }
    input {
      border-bottom: 1px solid #e5e5e5 !important;
      border-top-style: none !important;
      border-left-style: none !important;
      border-right-style: none !important;
    }
  }

  // accordion title
  .uk-accordion-title {
    font-size: 16px;
  }

  // accordion content
  .uk-accordion-content {
    p {
      font-size: 14px;
    }
  }

  // the pagination
  .dossier-pagination {
    margin-bottom: 0;
    .dossier-pagination-active {
      border-radius: 50%;
      background-color: $primary-medium;
      a {
        color: $white !important;
      }
    }
  }

  // The paperclip in the textarea
  .paperclip-mailbox {
    margin-top: 10px;
    position: absolute;
    border-radius: 5px;
    border: 1px solid $primary-medium;
    padding: 5px 7px;
    color: $primary-medium;
    height: 30px;
    width: 30px;
    @media only screen and (max-width : 480px) {
      right: 34px;
    }
    @media only screen and (min-width : 481px) {
      right: 60px;
    }
  }

  // The switcher in the 'berichtenbox' - active
  .active-switcher {
    a {
      background-color: transparent;
      color: $primary-medium; border: 1px solid $primary-medium;
      text-transform: capitalize;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  // The switcher in the 'berichtenbox' - Not active
  .not-active-switcher {
    padding-left: 0;
    a {
      background-color: transparent;
      color: #cbb3cd;
      border: 1px solid #CBB3CD;
      text-transform: capitalize;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  // The body space in different lists
  .dossier-list-body {
    @media only screen and (max-width : 480px) {
      padding: 25px;
    }
    @media only screen and (min-width : 481px) {
      padding: 20px 50px 20px 50px;
    }
  }

  // The body space in a list with documents
  .dossier-list-with-documents {
    padding: 20px 50px 50px 50px;
    .the-document {
      padding-left: 20%;
    }
  }

  // The body space in a list with pagination
  .dossier-list-with-pagination {
    @media only screen and (max-width : 480px) {
      padding: 25px;
    }
    @media only screen and (min-width : 481px) {
      padding: 20px 50px 50px 50px;
    }
  }

  // The body space in the 'berichtenbox'
  .dossier-messagebox {
    @media only screen and (max-width : 480px) {
      padding-left: 30px !important;
    }
    .dossier-messagebox-header-date {
      width: 25%;
    }
    .dossier-messagebox-header-sender {
      width: 25%;
    }
    .dossier-messagebox-header-subject {
      @media only screen and (max-width : 960px) {
        padding-left: 0 !important;
      }
      @media only screen and (min-width : 961px) {
        width: 15%;
      }
    }
    .dossier-messagebox-details-date {
      @media only screen and (max-width : 480px) {
        width: 50%;
      }
      @media only screen and (min-width : 481px) {
        width: 25%;
      }
    }
    .dossier-messagebox-details-sender {
      width: 23%;
    }
    .dossier-messagebox-details-subject {
      @media only screen and (max-width : 960px) {
        .uk-align-right {
          float: left !important;
          margin-left: 0 !important;
        }
      }
      @media only screen and (min-width : 961px) {
        width: 19%;
      }
    }
    .accordion-position {
      margin-top: -25px;
    }
    .dossier-messagebox-message-litigant {
      background-color: $primary-off-white;
      padding: 20px;
      margin: 20px 0 20px 40px;
    }
    .dossier-messagebox-message-counselor {
      background-color: #F0F0F0;
      padding: 20px;
      margin: 20px 40px 20px 0;
    }
    @media only screen and (max-width : 480px) {
      padding: 25px;
    }
    @media only screen and (min-width : 481px) {
      padding: 50px;
    }
  }
}

// The hulp card that we used for the mobile nav
.hulp-card {
  @media only screen and (max-width : 480px) {
    display: none;
  }

  @media only screen and (min-width : 480px) {
    &.card-bm {
      width: 80%;
      margin: auto;
    }
  }

  &.show-card {
    display: inline;
  }
}

// The dossier hulp container
.dossier-hulp-container {
  @media only screen and (max-width : 480px) {
    display: none;
  }

  // The hulp container body
  .uk-card-body {
    @media only screen and (max-width : 480px) {
      padding: 30px 25px;
    }
  }

  // The ranking number for a organisation
  .ranking-number {
    @media only screen and (max-width : 480px) {
      padding-top: 73px;
    }
    i {
      color: orange;
    }
  }

  // hr
  .hulp-hr {
    margin: 0;
  }

  // The container hulpheader
  .hulp-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $primary-deep;
    padding: 20px 50px;
    @media only screen and (max-width : 480px) {
      padding: 15px 25px;
    }
    h3 {
      color: $white;
    }
  }

  // The hulp container subtitle
  .dossier-hulp-subtitle {
    padding: 15px 50px;
    background-color: $primary-off-white;
    color: $primary-text-color;
    font-size: 13px;

    @media only screen and (max-width : 480px) {
      padding: 15px 20px;
    }
  }

  // The hulp container body
  .dossier-hulp-body {
    @media only screen and (min-width : 480px) {
      padding: 50px;
      .margin-none {
        margin: 0 !important;
      }
      .margin-bottom-five {
        margin-bottom: 5px !important;
      }
      .margin-bottom-twenty {
        margin-bottom: 20px !important;
      }
    }
    &.dossier-hulp-body-name-organisation {
      padding-bottom: 15px;
    }
    .dossier-hulp-select-organisation-title {
      .uk-badge-notification {
        background-color: $primary-medium !important;
      }
      span {
        padding-left: 10px;
        color: $primary-text-color;
      }
    }
    .dossier-hulp-title {
      margin-top: -35px !important;
      .uk-badge-notification {
        background-color: $primary-medium !important;
      }
      span {
        padding-left: 10px;
        color: $primary-text-color;
      }
    }
    .second-btn {
      margin-left: 10px;
      margin-top: 6px;
    }
    .first-btn {
      margin-top: 6px;
    }
    p {
      padding-top: 20px;
    }
    .hulp-organisation-name {
      padding-top: 0;
    }
    .hulp-organisation-status {
      padding-top: 0;
      margin-bottom: 30px;
    }
    .hulp-organisation-time {
      padding-top: 0;
      margin-top: 5px;
    }
    .two-buttons {
      margin-left: 10px;
    }
  }

  // The persons that can see the dossier
  .dossier-shared-persons {
    li {
      height:40px !important;
    }
  }
}

// Menu for mobile
.sticky-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1030;
  .uk-card-default {
    box-shadow: 0 5px 15px #00000069 !important;
  }
  .bottom-nav-mobile {
    z-index: 980;
    padding: 5px;
    a {
      text-align: center;
      font-size: 13px !important;
    }
  }
  .bottom-nav-desktop {
    z-index: 980;
    padding: 10px 0;
    a {
      text-align: center;
      font-size: 13px !important;
    }
  }
}

// selection for example a checkbox
::selection {
  background: $primary-medium !important;
}

@import '../base/colors';

$modalBorderRadius: 4px;

.uk-modal.uk-open,
.uk-modal.uk-togglabe-leave {
  @media (min-width: 960px) {
    margin-left: 260px;
  }

  .uk-modal-dialog {
    .uk-modal-footer {
      border-bottom-left-radius: $modalBorderRadius;
      border-bottom-right-radius: $modalBorderRadius;
    }

    border-radius: $modalBorderRadius;
  }
}

.main-content-view .card-answers-title .question-sub-header h4 {
  text-transform: none;
}

.slider-question {
  .slidecontainer {
    position: relative;

    input {
      width: 100%;
    }

    .marker {
      margin-top: -8px;

      &:after {
        height: 17px
      }
    }

    .rc-slider {
      margin: 0 0 5px 0px;

      .rc-slider-track,
      .rc-slider-rail,
      .rc-slider-step {
        height: 3px;
        border-radius: unset;
        background-color: $white;

        .rc-slider-dot,
        .rc-slider-dot-active {
          color: $white;
          border-radius: 0;
          width: 3px;
          height: 15px;
          margin-left: -1.5px;
          border: 1px solid $white;
          top: -6px;
        }
      }

      .rc-slider-handle {
        width: 19px;
        height: 19px;
        margin-top: -8px;
        border-radius: 50%;
        border: 3px solid $white;
        background: $primary;
        cursor: pointer;

        &[aria-valuenow="0"],
        &[aria-valuenow="25"],
        &[aria-valuenow="50"],
        &[aria-valuenow="75"],
        &[aria-valuenow="100"] {
          &:after {
            pointer-events: none;
            content: "";
            width: 3px;
            height: 16px;
            background: $white;
            position: absolute;
            top: 0;
            left: 5px;
          }
        }

      }
    }
  }
}

.MuiDialog-root {
  @media (min-width: 960px) {
    padding-left: 260px;
  }
}

.available-worker-text {
  position: relative;

  .available-worker-icon {
    margin: 0 !important;
    position: absolute;
    right: 20px;
    top: 2px;
  }
}

.hide-without-errors {
  display: none;
}
.main-content-view {
  @media (min-width: 960px) {
    margin-left: 260px;
  }

  .component-wrapper {

  }

  .answers-block-desktop {
    margin-left: 10px;
    margin-right: 10px;

    .card-answers-body-mobile.uk-grid {
      @media (min-width: 1200px) {
        margin-left: -20px;
      }

      @media (max-width: 959px) {
        margin: 10px 5px 10px -5px !important;
        padding-left: 0;
      }
    }
  }

  .answer-cards {
    @media (min-width: 960px) {
      padding-left: 20px;
    }
    @media (max-width: 960px) {
      padding-left: 10px;
    }
  }

  .card-answers {
    margin-left: 0;

  }

  .card-answers-center-desktop {
    margin-left: 0;

    .transparent-bg.desktop {
      margin-left: auto;
      margin-right: auto;

    }
  }

  .card-answers-title {
    h2 {
      margin: 50px 40px;
      font-weight: 500;

      &.question-header {
        padding-left: 0 !important;
        @media (min-width: 1400px) {
          min-width: 1100px;
          &.question-header-error-dialog {
            min-width: unset;
            margin: 20px;
          }
        }
        &.start-question-header {
          @media (min-width: 1400px) {
            min-width: 1100px;
            &.question-header-error-dialog {
              min-width: unset;
              margin: 20px;
            }
          }
          min-width: unset;
        }
      }
    }
  }
}

.home-page-wrapper.hide-sidebar-when-embedded {

  .main-content-view {
    @media (min-width: 960px) {
      margin-left: unset;
    }
  }
}


.MuiDialog-root {
  @media (min-width: 960px) {
    padding-left: 0 !important;
  }
}
.question-sub-heading {
  color: $white;
}

.multiple-choice-text-answer {
  margin-top: 1em;
}

.hide-speech-to-text-icon.uk-icon-button {
  display: none;
}

.early-exit-markdown {
  strong {
    font-weight: 600;
  }

  em {
    color: $white
  }

  del {
    text-decoration: underline;
  }

  ul, ol {
    color: $white;
    margin: auto auto 20px auto;
    width: fit-content;
    /*
    margin-bottom 20px is same as para + ul from ui-kit
    either use fit content or text-align
    text-align: left;
     */
  }

  ol li,
  ul li {
    white-space: nowrap;
  }
}
.share-error {
  color: $red;
  font-style: italic;
}

.save-dialog-anonymous-state.uk-grid {
  display: none;
}
.hide-save-dialog-close-button.MuiButtonBase-root {
  display: none;
}

#close-email-dialog-button:disabled {
  background: $lighter-grey !important;
  cursor: not-allowed;
  pointer-events: all;
}
.min-width-popup-dialog {
  min-width: 400px;
}
.gi-law-user-dropdown-holder.uk-inline {
  display: flex;
  align-items: center;
}

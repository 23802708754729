@import '../base/colors';

.uk-checkbox:checked {
  background-color: $primary !important;
}

.uk-checkbox {
  border-radius: 4px !important;
}

.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {
  background-color: $primary !important;
}

// Specific classes about the question pages for the customer
@import '../base/colors';

$contentBufferMobile: 25px;
$contentBufferDesktop: 10px;


html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-size: 100% 100%;
  z-index: -100;
}

.fixed-background-full-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: $secondary-bg-color;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
}

.main-navigation {
  width: 100%;
  @media (max-width: 959px) {
    justify-content: start !important;
  }

  .save-button-wrapper {
    @media (min-width: 960px) {
      margin-right: 25px;
      margin-left: auto;
    }

    .save-button {
      @media (min-width: 960px) {
        &:hover {
          color: $white !important;
          background: rgba(255, 255, 255, 0.3) !important;
        }
        i {
           margin-left: 4px;
        }
      }
      @media (max-width: 959px) {
        margin-left: 9px;
        margin-right: 10px;
        margin-top: 22px;

        color: $primary !important;
        background-color: $white;
        border: 1px solid $primary !important;
      }

    }


  }

  .help-button-top {
    @media (max-width: 959px) {
      margin-top: 22px;
      margin-right: auto;
    }
  }

  .speak-text-button.hover-transparent {
    position: absolute;
    border-radius: 50%;
    width: 36px;
    height: 36px;

    i {
      font-size: 14px;
    }

    @media (min-width: 960px) {
      background-color: transparent;
      border: thin $white solid;
      right: 170px;
      color: $white;
      &.fix-desktop-position {
        right: 176px;
      }
      i {
        color: $white;
      }
      &:hover {
        border-radius: 50% !important;
        color: $white !important;
        background: rgba(255, 255, 255, 0.3) !important;
      }
    }
    @media (max-width: 959px) {
      color: $primary;
      margin-top: 11px;
      border: 1px solid $primary;
      right: 24px;
    }
  }

  @media (max-width: 959px) {
    background-color: $white;
  }

  .uk-navbar {
    border-bottom: 1px $white solid;
  }
}


.progress-left-bar {
  position: initial;

  .progress-left-bar-body {
    width: 200px;
    max-width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed !important;
    background-color: $white;

    .uk-border-circle {
      p {
        margin-top: 5px;
      }
    }

    .navigation-bar-wrapper {


      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }
      // firefox
      scrollbar-width: none !important;
      overflow-y: scroll;
      overflow-x: hidden;

      .nav-item-wrapper {
        display: flex;
        flex-direction: row;
        margin: 20px 5px 20px 1px;
        overflow: hidden;
        width: 198px;
        max-width: 198px;
        min-width: 198px;
        cursor: pointer
      }
    }

    .progress-left-bar-number {
      display: block;
      color: $white;
      height: 35px;
      min-height: 35px;
      width: 35px;
      min-width: 35px;
      text-align: center;

      &.uk-align-left {
        margin: 0 25px 0 0;
      }

      &.circle-active {
        background-color: $primary;
      }

      &.circle-done {
        background-color: $circle-done;
      }
    }

    .progress-left-bar-item {
      margin-left: -20px;

      .tooltip-left-navigation-answer.item-p {
        margin-bottom: 0;
        margin-top: 10px;
        line-height: 1.2;
        color: #8c8989;
      }
    }

    .progress-left-bar-footer {
      margin-left: -30px;

      h4 {
        margin-bottom: 20px;
      }
    }

    .icon-active {
      margin-left: 53px;
      color: $green;
    }
  }
}

// The background
.background-gradient {
  position: relative;
}


.component-wrapper {
  padding: 0 !important;
  display: inline-block;

  &.center-everything {
    display: flex;
    justify-content: center;
  }
}


// Navbar
.uk-navbar {
  border-bottom: 1px $white solid;
}

nav {
  @media (max-width: 959px) {
    background-color: $white;
  }

  a {
    text-decoration: none !important;
  }
}

.home-btn {
  margin-left: 285px;
}

.home-page-wrapper.hide-sidebar-when-embedded {

  .home-btn {
    margin-left: 25px;
  }
}
.card-answers-p {
  @media (max-width: 959px) {
    font-size: 13px;
  }
}
.is-in-frame{

  .main-content-view {
    .answers-block {
      .card-answers-wrapper.uk-grid {
        .answer-cards {
          .card-answers.selected {
            img.icon,
            div.icon svg {
              filter: $icon-filter-frame;
            }
            p {
              color: #666666;
            }
            &:hover {
              img.icon,
              div.icon svg {
                filter: $icon-filter-frame;
              }
              p {
                color: #666666;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
.main-content-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  height: -webkit-calc(100% - 101px);
  height: -moz-calc(100% - 101px);
  height: calc(100% - 101px);
  @media (min-width: 960px) {
    margin-left: 260px;
  }

  .transparent-bg {
    background-color: $ash-grey;
    border-radius: 10px;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;

    &.start-page-wrapper {
      padding: 0;
      width: calc(40vw + 60px);
      .text-before-start-button {
        margin-right: 15px;
      }

      @media (max-width: 959px) {
        width: 94vw;
      }
      @media (max-width: 549px) {
        font-size: 14px;
      }

      .start-page-inner {
        padding: 30px 30px 30px 30px;
        width: 40vw;
        margin-left: auto;
        margin-right: auto;
      }

      .start-page-footer {
        padding: 25px 30px 10px 30px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .uk-icon-button {
          background-color: transparent;
        }
      }

      @media (max-width: 959px) {
        .start-page-inner {
          width: 74vw;
        }
      }
    }

    &.file-upload-question {
      padding: 30px 0 0 0;

      .file-upload-question-inner {
        padding: 30px 30px 0 30px;
        @media (max-width: 959px) {
          padding: 30px 30px 60px 30px;
        }
      }

      .checkbox-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 72px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        label {
          margin: auto;
        }
      }
    }

    .scan-button {
      margin-bottom: 20px;
    }

    &.question-flow-text-area {
      background-color: $ash-grey;
      padding: 0 0 0 0;
      @media (max-width: 959px) {
        width: 74vw;
      }

      .question-flow-text-area-inner {
        padding: 30px;
        border: 2px solid transparent;
      }


      &.with-focus {
        .question-flow-text-area-inner {
          border: 2px solid $white;
          border-radius: 10px;
        }

        &.question-with-skip {
          .question-flow-text-area-inner {
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
          }
        }
      }

      &.question-with-skip {
        .checkbox-footer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 72px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          label {
            margin: auto;
          }
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }

      textarea {
        &:focus::placeholder,
        &.is-recording::placeholder {
          color: transparent;
        }

        resize: none;

        &::placeholder {
          color: $white;
          font-size: 16px;
          font-family: 'Montserrat', Fira Sans Condensed, sans-serif;
          text-align: center;
        }

        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: 100%;
        height: 150px;
        border-color: transparent;
        background-color: transparent;
        color: $white;
        text-align: center;
      }
    }

    &.with-skip-question-footer {
      @media (min-width: 960px) {
        padding: 30px 30px 40px 30px;
      }
    }

    &.mobile-smileys {
      @media (max-width: 959px) {
        padding: 12px;
      }
    }

    &.desktop {
      @media (min-width: 960px) {
        width: 40vw;
      }
    }

    &.datepicker {
      padding: 0;
      background-color: unset;
    }

    &.slider-question {
      padding: 50px 20px 5px 20px;
    }

    &.smileys-wrapper,
    &.yes-no-question-wrapper {
      @media (max-width: 959px) {
        padding: 12px;
        width: 50vw;
      }

      .yes-no-question {
        .selected {
          background-color: white !important;
          color: $primary-blue !important;
        }
      }
    }

    @media (min-width: 960px) {
      width: 40vw;
    }
    @media (max-width: 959px) {
      width: 74vw;
    }

    &.size-block {
      @media (min-width: 960px) {
        width: 400px;
      }
      @media (max-width: 959px) {
        width: 72vw;
        &.first-screen {
          @media (max-width: 350px) {
            font-size: 12px !important;
            margin-top: -15px !important;
          }
        }
      }
    }

    &.size-block-long-text {
      @media (max-width: 959px) {
        width: 80%;
        margin: auto;
        .checkbox-footer {
          margin-top: 20px;
        }
        p {
          font-size: 13px;
        }
        label {
          font-size: 13px;
        }
      }
    }

    h4 {
      color: $white;
    }

    p {
      color: $white;
      font-weight: 300;
    }

    .icon {
      color: $white;
    }

    label {
      font-weight: 300;
      color: $white;
      margin-top: 10px;
    }

    .btn-icon {
      color: $primary;
    }

    .first-button {
      @media (min-width: 960px) {
        margin-bottom: 15px;
      }
    }

    .second-button {
      @media (min-width: 960px) {
        margin-bottom: 30px;
      }
    }

    .title-transparent {
      @media (max-width: 959px) {
        margin-bottom: 25px;
      }
    }

    .different-buttons {
      button {
        margin: 8px;
      }
    }

    .c-datepicker-input {
      ::placeholder {
        color: $white !important;
        font-size: 16px !important;
        font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;
        text-align: center !important;
      }
    }

    textarea {
      font-family: 'Montserrat', Fira Sans Condensed, sans-serif !important;
    }
  }

  .start-screen {
    @media (max-width: 361px) {
      iframe {
        display: none;
      }
    }

    .text-and-btn {
      margin-right: 15px;
    }

    ifame {
      margin-bottom: 15px;
    }

    .start-screen-last-explanation {
      margin-bottom: 0;
    }
  }

  .card-answers-title {

    @media (max-width: 959px) {
      padding-top: 20px;
    }

    h1 {
      margin: 60px 0 50px 0;
      text-transform: uppercase;
    }

    h2 {
      margin: 40px auto;
      font-size: 1.8rem;

      &.subject-header {
        margin: 50px 0 10px 0;
      }

      &.question-header {
        @media (max-width: 959px) {
          font-size: 20px;
          margin: 0 $contentBufferMobile 40px $contentBufferMobile;
        }

      }

    }

    h4 {
      padding-bottom: 15px;
      text-transform: uppercase;
    }

    img {
      @media (min-width: 960px) {
        margin-bottom: 45px;
      }
    }

    // header mobile to be removed
    .img-mobile-header {
      @media (max-width: 959px) {
        height: 50px;
      }
    }

    .title-mobile-with-img {
      margin: 15px 0 -5px 0;
    }
  }

  // question-with-icons && questions-without-icons
  .answers-block {

    @media (min-width: 960px) {
      margin-left: $contentBufferDesktop;
      margin-right: $contentBufferDesktop;
      .subtitle {
        margin: -11px 0 40px 0;
        font-size: 18px !important;
      }
      p {
        color: $white;
        margin-top: 15px !important;
      }
      .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
        margin-top: 20px;
      }
      .uk-card-body {
        padding: 20px 10px !important;
      }
    }
    @media (max-width: 1249px) {
      margin-left: $contentBufferMobile;
      margin-right: $contentBufferMobile;
      .answer-card {
        width: 80% !important;
      }
      .subtitle {
        margin: -30px 0 20px 0;
      }
      p {
        color: $white;
      }
      .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
        //margin-top: 10px !important;
      }
      .uk-card-body {
        padding: 20px 10px !important;
      }
    }


    .card-answers-body {
      @media (max-width: 959px) {
        margin: 10px auto 10px -10px !important;
      }
    }

    .card-answers-wrapper.uk-grid {
      @media (min-width: 1200px) {
        margin-left: -20px;
      }
      @media (min-width: 960px) {
        margin-top: 10px !important;
        margin-left: -20px;
      }

      &.with-icons {
        @media (max-width: 959px) {
          margin: 10px auto 10px -10px !important;
        }
      }

      .answer-cards {
        @media (min-width: 960px) {

          cursor: pointer;
          display: block;
          padding-left: 20px;
          img {
            height: 60px;
          }
        }
        @media (max-width: 959px) {
          cursor: pointer;
          display: block;
          padding-left: 10px;
          img {
            height: 30px;
          }
          p {
            margin-top: 10px;

            &.without-icon {
              margin-top: 0 !important;
            }
          }
        }

        .card-answers {
          border-radius: 4px;
          border: 1px solid $white;
          background-color: transparent;
          color: $white;
          height: 100%;
          align-content: center;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;

          &.card-answers-text {
            @media (max-width: 959px) {
              min-height: 90px;
            }
            @media (min-width: 960px) {
              min-height: 120px;
            }
          }

          p {
            font-size: 20px;
            word-break: break-word;
            hyphens: auto;

            user-select: none;

            &.without-icon {
              margin-top: 0 !important;
            }
          }

          img {
            user-select: none;
          }

          &.selected {
            background-color: white;
            color: $primary-blue;

            img.icon,
            div.icon svg {
              filter: $icon-filter-no-frame;
            }

            p {
              color: $primary-blue;
            }
          }
        }

        .card-answers:hover {
          background: rgba(255, 255, 255, 0.2);

          &.selected {
            background: $white;

            img.icon,
            div.icon svg {
              filter: $icon-filter-no-frame;
              opacity: 0.5;
            }

            p {
              color: $primary;
              opacity: 0.5;
            }
          }
        }

      }
    }
  }
  .text-node-wrapper {

    border: thin white solid;
    .rhetorical-question-image-wrapper {
      max-width: 300px;
      min-height: 150px;
      margin-left: auto;
      margin-right: auto;
      .rhetorical-question-image {
        width: 100%;
        max-width:100% !important;
      }
    }
  }
}


.datepicker {
  @media (min-width: 960px) {
    margin-left: 60px;
    span {
      font-size: 18px;
    }
  }

  .fa-calendar-alt {
    font-size: 27px !important;
  }

  input:not([type='checkbox']) {
    font-size: 18px;
    font-family: 'Montserrat', Fira Sans Condensed, sans-serif;
    position: relative;
    cursor: pointer;
    @media (max-width: 959px) {
      width: 58vw;
    }
    @media (min-width: 960px) {
      width: 35vw;
    }
  }

  .checkbox-footer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 72px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    label {
      margin: auto;
    }
  }
}

.accent-footer {
  background-color: $accent-footer;
}


.uk-card-default {
  border: 1px solid $white;
  background: transparent;
  color: $white;
}

.uk-card-default.uk-card-hover:hover {
  color: $white;
  background: rgba(255, 255, 255, 0.3);
}

.uk-card-body {
  padding: 20px 50px;
}


.card-answers-space {
  @media (max-width: 959px) {
    padding: 15px !important;
  }
}

.card-answers-space:focus {
  color: $primary !important;
  background: $white !important;
}

.slidecontainer {
  position: relative;

  input {
    width: 100%;
  }
}

.slider-title {
  @media (min-width: 960px) {
    margin: 10px auto 30px auto;
  }
}

.slider {
  -webkit-appearance: none;
  margin-bottom: 10px;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background: $white;
  position: relative;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 3px solid $white;
  background: $primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid $white;
  background: $primary;
  cursor: pointer;
}

.transparent-bg-lighter {
  background-color: $accent-footer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -10px;
  @media (max-width: 959px) {
    padding: 30px;
  }
  @media (min-width: 960px) {
    width: 40vw;
    padding: 25px 30px 10px 30px;
    &.center {
      padding: 25px 30px 25px 30px;
    }
  }

  p {
    color: $white !important;
  }

  .uk-align-left {
    margin-right: 20px !important;
  }

  [class*='uk-align'] {
    margin-bottom: 0 !important;
  }

  span {
    color: white;
    padding-left: 10px;
  }
}

.c-datepicker-btn {
  display: inline-block;
  width: 100%;
  background-color: $ash-grey;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &.question-without-skip {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  input {
    background-color: transparent;
    border-color: transparent;
    color: $white;
  }

  input::placeholder {
    color: $white;
    opacity: 0.6;
  }
}

.c-datepicker__header-date {
  background: $secondary-bg-color !important;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  padding: 16px 0 0 0 !important;
}

.c-datepicker__header-day {
  background: $secondary-bg-color !important;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%) !important;
}

.u-hover-ball-effect:hover:before, .c-datepicker__day-body:hover:before, .c-datepicker__clock__num:hover:before, .c-datepicker__clock__am-pm-toggle label:hover:before {
  background: $primary !important;
}

.c-datepicker--show-time:after {
  opacity: initial !important;
  visibility: unset !important;
}

.c-datepicker__header-date__time {
  opacity: 0 !important;
}

.js-show-calendar {
  opacity: 0 !important;
}

.js-cancel {
  color: $medium-grey !important;
}

.js-ok {
  color: $primary !important;
}

.smiley-answer {
  @media (min-width: 960px) {
    img {
      width: 60px;
      margin: 0 10px 15px 10px;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .smiley-wrapper {
    border-radius: 50%;
    border: 2px solid $white;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    &.selected {
      background-color: $white;

      img {
         filter: $icon-filter-no-frame;
      }
    }

    &.selected:hover {
      background: $white;

      img {
         filter: $icon-filter-no-frame;
        opacity: 0.5;
      }
    }

    @media (max-width: 959px) {
      img {
        width: 36px;
      }
      margin: 15px 5px;
    }

    @media (min-width: 1400px) {
      img {
        width: 56px;
      }
      margin: 15px 10px;
    }

    @media (min-width: 960px) and (max-width: 1199px) {
      img {
        width: 38px;
      }
      margin: 8px 5px;
    }

    @media (max-width: 560px) {
      img {
        width: 34px;
      }
      margin: 7px 4px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
      img {
        width: 46px;
      }
      margin: 10px 7px;
    }

    img {
      cursor: pointer;
      pointer-events: auto;
      border-radius: 50%;
    }
  }

}


.progress-left-bar {
  position: initial;

  .progress-left-bar-body {
    width: 200px;
    max-width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed !important;
    background-color: $white;

    .uk-border-circle {
      p {
        margin-top: 5px;
      }
    }

    .progress-left-bar-number {
      margin: 20px 5px 20px 1px;
      display: block;
      color: $white;
      height: 35px;
      width: 35px;
      text-align: center;

      &.circle-active {
        background-color: $primary;
      }

      &.circle-done {
        background-color: $circle-done;
      }
    }

    .progress-left-bar-item {
      margin-left: -10px;
    }

    .progress-left-bar-footer {
      margin-left: -30px;

      h4 {
        margin-bottom: 20px;
      }
    }

    .icon-active {
      margin-left: 53px;
      color: $green;
    }
  }
}

.margin-bottom-white-balk {
  margin-bottom: -4px;
}

.uk-position-bottom-gl {
  bottom: 39px;

  h4 {
    line-height: 0.4pt;
  }

  p {
    margin-right: 10px;
  }

  span {
    margin-left: 53px;

    &.MuiButton-label {
      margin-left: 0px;
      color: $primary;
    }

    color: $green;
  }
}

.progress-left-bar-footer-title {
  margin-top: 30px;
}

.progress-left-bar-footer-hr {
  margin: 0 -23px 0 -30px;
}

.question-answer-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 92px;
}

// Global changes
.no-scroll-bottom {
  position: absolute;
}

.item-p {
  color: $primary-text-color;
}

.uploaded-files-wrapper {
  display: flex;
  width: 400px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  .uploaded-file-item-wrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    p.uploaded-file-item {
      a,
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $white !important;
        -webkit-text-fill-color: $white;
        text-decoration: none !important;
      }
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      width: 300px;
      margin: 0 30px;
    }
    .gi-law-delete-file-button {
      width: 20px;
      height: 20px;
      padding: 2px;
      position: absolute;
      background-color: $white;
      top: 2px;
      right: 0;
      svg {
        width: 25px;
      }

    }
  }
}


@media (max-width: 959px) {
  .question-sub-header {
    padding:  0 10px;
    margin: -20px 25px 0 25px !important;
    &.question-sub-header-error-dialog {
      margin-top: 10px !important;
    }
    h4 {
      font-size: 15px;
    }
  }
}

.result-page-wrapper {
  .result-page-definition-list{
    color: $white
  }
  dl {
    dt {

    }
    dd {

    }
  }
  h3 {
    color: $white;
  }
}

@media (min-width: 960px) {
  .question-sub-header {
    padding: 0 40px;
    margin: -40px auto 40px auto !important;
    &.question-sub-header-error-dialog {
      margin-top: 10px !important;
    }
    h4 {
      font-size: 16px !important;
    }
  }
}

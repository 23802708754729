@import '../base/colors';

.text-two-lines {
  overflow: hidden;
  line-height: 20px;
  max-height: 40px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
